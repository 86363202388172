import { Dimensions } from "react-native";
import styled from "@emotion/native";
import { Text } from "myplan";

export const ScrollContainer = styled.ScrollView(
	{
		flex: 1,
	},
	({ theme }: any) => {
		return {
			backgroundColor: theme.color.background,
		};
	}
);

export const ModalText = styled(Text)({
	marginTop: 150,
	marginBottom: 40,
	marginHorizontal: 25,
	alignSelf: "flex-start",
});

export const LocalizationHeadline = styled(Text)({
	marginTop: 20,
	marginBottom: 40,
	marginHorizontal: 25,
	alignSelf: "flex-start",
});

export const ButtonContainer = styled.View({
	marginBottom: 10,
});

export const StudyId = styled(Text)({
	fontSize: 14,
	marginTop: "auto",
	marginBottom: 120,
});

export const AppIconSection = styled.View({
	flex: 1,
	alignItems: "center",
	width: Dimensions.get("window").width - 50,
	borderRadius: 12,
	backgroundColor: "white",
	padding: 25,
	marginBottom: 100,
});

export const AppIconSelectionDescription = styled(Text)({
	color: "black",
	marginBottom: 25,
});

export const IconOption = styled.TouchableOpacity({
	flex: 1,
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	paddingHorizontal: 8,
	marginBottom: 25,
});

export const IconOptionTitle = styled(Text)({}, ({ selected }: any) => {
	return {
		color: selected ? "#30B7B6" : "#000",
	};
});

export const IconImage = styled.Image({
	width: 60,
	height: 60,
	marginRight: 30,
	borderRadius: 12,
});

export const IconRadioButtonOuter = styled.View(
	{
		height: 24,
		width: 24,
		borderRadius: 12,
		borderWidth: 2,
		alignItems: "center",
		justifyContent: "center",
		marginRight: 10,
	},
	({ selected }: any) => {
		return {
			borderColor: selected ? "#30B7B6" : "#000",
		};
	}
);

export const IconRadioButtonInner = styled.View({
	height: 12,
	width: 12,
	borderRadius: 6,
	backgroundColor: "#30B7B6",
});
