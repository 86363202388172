import styled from "@emotion/native";
import { Button } from "myplan";

export const Container = styled.View({
	position: "relative",
	width: 300,
	marginBottom: 20,
});

export const RadioTrigger = styled(Button)({
	width: 320,
});

import * as React from "react";
import Svg, { Path } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const CheckIcon = ({
	width = 26,
	height = 20,
	color = "#FFF",
	...props
}) => (
	<Svg width={26} height={20} color={color} {...props} viewBox="0 0 52 40">
		<Path
			d="M46.855.002a5.014 5.014 0 0 0-3.433 1.529c-9.565 9.584-16.868 17.589-25.747 26.651L8.22 20.193a5.014 5.014 0 0 0-3.647-1.162 5 5 0 0 0-4.553 5.414A4.993 4.993 0 0 0 1.79 27.84l12.983 10.985a4.99 4.99 0 0 0 6.741-.28c10.756-10.78 18.413-19.388 28.961-29.96a4.994 4.994 0 0 0-3.62-8.582Z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</Svg>
);

export const Check = styled(CheckIcon)({
	position: "absolute",
	zIndex: 2,
	left: 12,
	top: 6,
});
