import styled from "@emotion/native";
import {
    useSafeAreaInsets,
    SafeAreaView,
} from "react-native-safe-area-context";
import { CTA_HEIGHT } from "myplan/components/OnboardingSlide/styles";

export { CTA_HEIGHT } from "myplan/components/OnboardingSlide/styles";

export const Container = styled(SafeAreaView)(
    {
        flex: 1,
        alignItems: "stretch",
    },
    ({ theme }: any) => {
        console.log("theme", theme);
        return {
            backgroundColor: theme.color.background,
        };
    },
);

export const Skip = styled.View({});

// export const CarouselContainer = styled.View({
//     // flex: 1,
// });

// export const CarouselItem = styled.View({
//     // flex: 1,
// });

export const PaginationContainer = styled.View(
    {
        position: "absolute",
        width: "100%",
        paddingVertical: 16,
        zIndex: -1,
    },
    () => {
        const insets = useSafeAreaInsets();

        return {
            bottom: insets.bottom + CTA_HEIGHT,
        };
    },
);

export const Cta = styled.View({
    height: CTA_HEIGHT,
    zIndex: 1,
});
