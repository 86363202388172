import React from "react";
import OnboardingSlideStudyId from "./components/OnboardingSlideStudyId";
import OnboardingSlideDefault from "./components/OnboardingSlideDefault";
import OnboardingSlidePin from "./components/OnboardingSlidePin";
import OnboardingSlideSafeMode from "./components/OnboardingSlideSafeMode";
import OnboardingSlideReverse from "./components/OnboardingSlideReverse";
import OnboardingSlideSelect from "./components/OnboardingSlideSelect";

export enum OnboardingSlideType {
    Default = "DEFAULT",
    Reverse = "REVERSE",
    Pin = "PIN",
    SafeMode = "SAFE_MODE",
    StudyId = "STUDY_ID",
    Select = "SELECT",
    Country = "COUNTRY",
    Language = "LANGUAGE",
    Path = "PATH",
    Audience = "AUDIENCE",
}

export type OnboardingOption = {
    key: string;
    value: string;
};

export type OnboardingSlideProps = {
    type?: OnboardingSlideType;
    imageUrl?: string;
    title?: string;
    body?: string;
    caption?: string;
    isActive: boolean;
    value?: string;
    options?: OnboardingOption[];
    errorMessage?: string;
    onValueChange?: (pin: string) => void;
};

const OnboardingSlide = ({ type, ...props }: OnboardingSlideProps) => {
    let content;

    switch (type) {
        case OnboardingSlideType.StudyId:
            content = <OnboardingSlideStudyId {...props} />;
            break;

        case OnboardingSlideType.Pin:
            content = <OnboardingSlidePin {...props} />;
            break;

        case OnboardingSlideType.SafeMode:
            content = <OnboardingSlideSafeMode {...props} />;
            break;

        case OnboardingSlideType.Reverse:
            content = <OnboardingSlideReverse {...props} />;
            break;

        case OnboardingSlideType.Select:
        case OnboardingSlideType.Country:
        case OnboardingSlideType.Language:
        case OnboardingSlideType.Audience:
            content = <OnboardingSlideSelect {...props} />;
            break;

        default:
            content = <OnboardingSlideDefault {...props} />;
            break;
    }

    return content;
};

export default OnboardingSlide;
