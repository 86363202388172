import styled from "@emotion/native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

const SAFE_MODE_MARGIN = 20;
const MAX_SAFE_MODE_MARGIN = 26;

export const Container = styled.View(
	{
		flex: 1,
	},
	({ theme }: any) => {
		return {
			backgroundColor: theme.color.background,
		};
	}
);

export const SafeModeButtonContainer = styled.View(
	{
		position: "absolute",
	},
	({ theme }: any) => {
		const insets = useSafeAreaInsets();
		const delta = 1 - theme.verticalScale;
		let bottom = SAFE_MODE_MARGIN + insets.bottom;
		let right = SAFE_MODE_MARGIN;

		if (bottom > MAX_SAFE_MODE_MARGIN) {
			bottom = MAX_SAFE_MODE_MARGIN;
		}

		if (delta > 0) {
			const verticalScale = 1 - delta / 1;

			bottom = bottom * verticalScale;
			right = right * verticalScale;
		}

		return {
			right,
			bottom,
		};
	}
);
