import { Platform } from "react-native";
import styled from "@emotion/native";
import { Text } from "myplan";
import { AppTheme } from "myplan/utils/theme";
import * as drawer from "myplan/utils/drawer";

export const Container = styled.View(
    {
        flex: 1,
        justifyContent: "center",
        alignContent: "center",
    },
    ({ theme }: any) => {
        const {
            color: { background },
        } = theme as AppTheme;
        return {
            backgroundColor: background,
        };
    },
);

export const Scroll = styled.ScrollView({
    marginTop: drawer.BUTTON_TOP + drawer.BUTTON_HEIGHT + 18,
    overflow: Platform.OS === ("android" || "web") ? "scroll" : "visible",
});

export const Title = styled(Text)({
    paddingHorizontal: 13,
    marginBottom: 38,
});

export const LoadingTitle = styled(Text)({
    paddingHorizontal: 13,
    marginVertical: 38,
    textAlign: "center",
});

export const Intro = styled(Text)({
    paddingHorizontal: 12,
    marginBottom: 15,
});

export const ReviewPrompt = styled(Text)({
    marginLeft: 12,
    marginBottom: 26,
});
