import React, { useContext } from "react";
import { useTheme } from "@emotion/react";
import { AppTheme } from "myplan/utils/theme";
import { useOpenUrl, UrlType } from "myplan/utils/url";
import FooterItem from "./components/FooterItem";
import { Context as PinContext } from "myplan/utils/pin";
import { FurnitureContext } from "myplan/utils/furniture";
import { LocaleContext, Locales } from "myplan/utils/locale";
import {
    CloseIcon,
    LockIcon,
    TrashIcon,
    SmsIcon as ChatIcon,
} from "myplan/components/Icon";
import { Text } from "myplan";
import {
    Container,
    Body,
    NavigationItem,
    Main,
    CloseButton,
    Footer,
} from "./styles";

const navigationItems = [
    {
        key: "Home",
        label: "Home",
        furnitureKey: "homeTitle",
        destination: "Home",
    },
    {
        key: "MyRelationship",
        label: "My Relationship",
        furnitureKey: "myRelationshipTitle",
        destination: "MyRelationship",
    },
    {
        key: "MyStrategies",
        label: "My Strategies",
        furnitureKey: "myStrategiesTitle",
        destination: "MyStrategies",
    },
    {
        key: "MyResources",
        label: "My Resources",
        furnitureKey: "myResourcesTitle",
        destination: "MyResources",
    },
    {
        key: "Learn",
        label: "My Learning",
        furnitureKey: "myLearningTitle",
        destination: "Learn",
    },
];

const Drawer = ({ navigation }) => {
    const [, setActivePin] = useContext(PinContext);
    const furniture = useContext(FurnitureContext);
    const { locale } = useContext(LocaleContext);
    const openUrl = useOpenUrl();
    const {
        color: { background },
    }: AppTheme = useTheme() as AppTheme;

    const lockScreen = () => {
        setActivePin(null);
    };

    return (
        <Container>
            <CloseButton onPress={() => navigation.closeDrawer()}>
                <CloseIcon color={background} width="58%" height="58%" />
            </CloseButton>
            <Body>
                <Main nativeID="drawer-nav-main">
                    {navigationItems.map(navigationItem => (
                        <NavigationItem
                            key={navigationItem.key}
                            onPress={() =>
                                navigation.navigate(navigationItem.destination)
                            }>
                            <Text type="h1">
                                {furniture[navigationItem.furnitureKey]}
                            </Text>
                        </NavigationItem>
                    ))}
                    <NavigationItem
                        onPress={() => {
                            navigation.navigate("Bookmarks");
                        }}>
                        <Text type="h4">{furniture.bookmarksTitle}</Text>
                    </NavigationItem>
                    <NavigationItem
                        onPress={() => {
                            navigation.navigate("MySettings");
                        }}>
                        <Text type="h4">{furniture.mySettingsTitle}</Text>
                    </NavigationItem>
                    <NavigationItem
                        onPress={() => {
                            navigation.navigate("About");
                        }}>
                        <Text type="h4">{furniture.aboutTitle}</Text>
                    </NavigationItem>
                </Main>
                <Footer>
                    <FooterItem
                        Icon={ChatIcon}
                        title={furniture.ndvhChatTitle}
                        caption={null}
                        onPress={() => {
                            openUrl(UrlType.Website, {
                                websiteUrl:
                                    locale === Locales.EN_US
                                        ? "https://www.thehotline.org/get-help/"
                                        : "https://espanol.thehotline.org/ayuda/",
                            });
                        }}
                    />
                    <FooterItem
                        Icon={LockIcon}
                        title={furniture.lockCtaTitle}
                        caption={furniture.lockCtaCaption}
                        onPress={lockScreen}
                    />
                    <FooterItem
                        Icon={TrashIcon}
                        title={furniture.deleteCtaTitle}
                        caption={furniture.deleteCtaCaption}
                        onPress={() => navigation.navigate("ModalScreen")}
                    />
                </Footer>
            </Body>
        </Container>
    );
};

export default Drawer;
