import React from "react";
import { Article } from "myplan";
import { OnboardingSlideProps } from "../../index";
import { Container, Image } from "../../styles";
import { Main, Accessory } from "./styles";

const OnboardingSlideSafeMode = ({
	title,
	body,
	caption,
	imageUrl,
}: OnboardingSlideProps) => {
	return (
		<Container>
			<Accessory>
				<Article
					type="safeMode"
					title={title}
					body={body}
					caption={caption}
				/>
				<Main isReverse>
					{imageUrl && <Image source={{ uri: imageUrl }} />}
				</Main>
			</Accessory>
		</Container>
	);
};

export default OnboardingSlideSafeMode;
