import React, { useContext, useState, useEffect } from "react";
import { Platform } from "react-native";
import AppIcon from "react-native-dynamic-app-icon";
import RadioButton from "components/RadioButton";
import { darkTheme, lightTheme, ThemeContext } from "../../utils/theme";
import { DrawerButton } from "myplan";
import { FurnitureContext } from "myplan/utils/furniture";
import { useExternalState } from "myplan/utils/me";
import { LocaleContext, Locales } from "myplan/utils/locale";
import { iconNames, iconThumbnails, STORED_ICON } from "../../utils/icon";
import { useStorage } from "myplan/utils/storage";
import Constants from "expo-constants";
import { MyPlanConstants } from "myplan";

const { spanishEnabled } = Constants.expoConfig?.extra as MyPlanConstants;

import {
    ScrollContainer,
    ModalText,
    ButtonContainer,
    StudyId,
    LocalizationHeadline,
    AppIconSection,
    AppIconSelectionDescription,
    IconOption,
    IconOptionTitle,
    IconImage,
    IconRadioButtonOuter,
    IconRadioButtonInner,
} from "./styles";

const { primaryIcon, timecheckIcon, organizationIcon } = iconThumbnails;
const { primary, timecheck, organization } = iconNames;

const MySettings = () => {
    const { userState } = useExternalState();
    const { locale, setLocale } = useContext(LocaleContext);
    const {
        theme: { name: themeName },
        updateTheme,
    } = useContext(ThemeContext);
    const {
        appIconDescription,
        appIconLabels,
        strings: {
            mySettingsChangeAppLook,
            lightModeCta,
            darkModeCta,
            mySettingsLocalization,
            englishCta,
            spanishCta,
        },
    } = useContext(FurnitureContext);
    const studyId = userState?.studyId || null;
    const [selectedIcon, setSelectedIcon] = useState(primary);
    const [storedIcon, setStoredIcon] = useStorage(STORED_ICON);
    useEffect(() => {
        if ([primary, timecheck, organization].includes(storedIcon)) {
            setSelectedIcon(storedIcon);
        }
    }, [storedIcon]);
    const setIcon = async icon => {
        try {
            AppIcon.setAppIcon(icon);
            setSelectedIcon(icon);
            setStoredIcon(icon);
        } catch (error) {
            console.log(error);
        }
    };

    // scroll indicator position bug, see https://github.com/facebook/react-native/issues/26610
    // setting scroll inset helps

    const renderIconSettings = () => {
        if (Platform.OS !== "ios") return null;
        return (
            <>
                <LocalizationHeadline type="h1">App Icon</LocalizationHeadline>
                <AppIconSection>
                    <AppIconSelectionDescription>
                        {appIconDescription}
                    </AppIconSelectionDescription>
                    <IconOption
                        onPress={() => {
                            setIcon(primary);
                        }}>
                        <IconImage source={primaryIcon} />
                        <IconRadioButton selected={selectedIcon === primary} />
                        <IconOptionTitle
                            type="body1"
                            selected={selectedIcon === primary}>
                            {appIconLabels[0]}
                        </IconOptionTitle>
                    </IconOption>
                    <IconOption
                        onPress={() => {
                            setIcon(timecheck);
                        }}>
                        <IconImage source={timecheckIcon} />
                        <IconRadioButton
                            selected={selectedIcon === timecheck}
                        />
                        <IconOptionTitle
                            type="body1"
                            selected={selectedIcon === timecheck}>
                            {appIconLabels[1]}
                        </IconOptionTitle>
                    </IconOption>
                    <IconOption
                        onPress={() => {
                            setIcon(organization);
                        }}>
                        <IconImage source={organizationIcon} />
                        <IconRadioButton
                            selected={selectedIcon === organization}
                        />
                        <IconOptionTitle
                            type="body1"
                            selected={selectedIcon === organization}>
                            {appIconLabels[2]}
                        </IconOptionTitle>
                    </IconOption>
                </AppIconSection>
            </>
        );
    };

    return (
        <ScrollContainer
            scrollIndicatorInsets={{ right: Number.MIN_VALUE }}
            contentContainerStyle={{
                alignItems: "center",
            }}>
            <DrawerButton />

            <ModalText type="h1">{mySettingsChangeAppLook}</ModalText>

            <ButtonContainer>
                <RadioButton
                    checked={themeName === "dark"}
                    title={darkModeCta}
                    onPress={() => {
                        updateTheme(darkTheme);
                    }}
                />
            </ButtonContainer>

            <ButtonContainer>
                <RadioButton
                    checked={themeName === "light"}
                    title={lightModeCta}
                    onPress={() => {
                        updateTheme(lightTheme);
                    }}
                />
            </ButtonContainer>
            {spanishEnabled && (
                <>
                    <LocalizationHeadline type="h1">
                        {mySettingsLocalization}
                    </LocalizationHeadline>
                    <ButtonContainer>
                        <RadioButton
                            checked={locale === Locales.EN_US}
                            title={englishCta}
                            onPress={() => {
                                setLocale(Locales.EN_US);
                            }}
                        />
                    </ButtonContainer>
                    <ButtonContainer>
                        <RadioButton
                            checked={locale === Locales.ES}
                            title={spanishCta}
                            onPress={() => {
                                setLocale(Locales.ES);
                            }}
                        />
                    </ButtonContainer>
                </>
            )}
            {renderIconSettings()}
            {studyId && (
                <StudyId type="mono4">{`Study ID: ${studyId}`}</StudyId>
            )}
        </ScrollContainer>
    );
};
export default MySettings;

const IconRadioButton = ({ selected }: any) => (
    <IconRadioButtonOuter selected={selected}>
        {selected && <IconRadioButtonInner />}
    </IconRadioButtonOuter>
);
