import React from "react";
import { Article } from "myplan";
import { ErrorBlock } from "myplan";
import { Keypad } from "myplan";
import { OnboardingSlideProps } from "../../index";
import { Container, Accessory, ErrorMain } from "../../styles";
import { KeypadContainer } from "./styles";

const OnboardingSlidePin = ({
	title,
	body,
	caption,
	value,
	onValueChange,
	errorMessage,
}: OnboardingSlideProps) => {
	return (
		<Container>
			<Accessory isFlexDisabled>
				<Article title={title} body={body} caption={caption} />
			</Accessory>
			{!!errorMessage && (
				<ErrorMain>
					<ErrorBlock message={errorMessage} />
				</ErrorMain>
			)}
			<KeypadContainer>
				<Keypad onChange={onValueChange} value={value} />
			</KeypadContainer>
		</Container>
	);
};

export default OnboardingSlidePin;
