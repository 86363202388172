import { Platform } from "react-native";

declare global {
    interface Window {
        PerformanceNavigation: any;
        performance: any;
    }
}
/**
 * On web only, when the page reloads, lock the screen.
 * @param handle Function
 * @returns void
 */
export const onAfterReload = (handle?: Function) => {
    if (Platform.OS !== "web") return; // disable on native platforms
    if (__DEV__) return; // disable in DEV
    const w = global.window as Window;
    if (w.performance) {
        if (
            [
                w.PerformanceNavigation.TYPE_RELOAD,
                w.PerformanceNavigation.TYPE_NAVIGATE,
                w.PerformanceNavigation.TYPE_BACK_FORWARD,
            ].includes(w.performance.navigation.type)
        ) {
            handle();
        }
    }
};
