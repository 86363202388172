import React, { useState, useRef, useEffect, useContext } from "react";
import { LogBox } from "react-native";
import {
    useSafeAreaFrame,
    useSafeAreaInsets,
} from "react-native-safe-area-context";
import Pagination from "myplan/components/Pagination";
import Carousel, { ICarouselInstance } from "react-native-reanimated-carousel";
import { register } from "utils/auth";
import { useStorage } from "myplan/utils/storage";
import OnboardingSlide, {
    OnboardingSlideType,
} from "components/OnboardingSlide";
import { Button } from "myplan";
import {
    Context as PinContext,
    STORAGE_KEY_STORED_PIN,
} from "myplan/utils/pin";
import { Container, PaginationContainer, Cta, CTA_HEIGHT } from "./styles";
import { useOnboarding } from "myplan/utils/contentful";
import { LocaleContext, getBestLocale } from "myplan/utils/locale";
import {
    FurnitureContext,
    AudienceContext,
    Audience,
    Onboarding,
} from "myplan/utils/furniture";

LogBox.ignoreLogs(["Calling `getNode()`"]);

export type CarouselItem = {
    _createdAt?: string;
    _id?: string;
    _revision?: number;
    _type?: string;
    _updatedAt?: string;
    body?: string;
    caption?: string;
    image?: {
        _id?: string;
        contentType?: string;
        description?: undefined;
        dimensions?: { height?: number; width?: number };
        size?: number;
        title?: string;
        url?: string;
        version?: number;
    };
    title?: string;
    type?: string;
};

type CarouselProps = {
    item: CarouselItem;
    index: number;
};

const renderItem = ({
    item,
    index,
    setValue,
    activeSlideIndex,
    value,
    errorMessage,
}) => {
    const imageUrl = item.image?.url && `https:${item.image.url}`;
    if (item.type === OnboardingSlideType.Language) {
        return (
            <OnboardingSlide
                type={item.type}
                imageUrl={imageUrl}
                title={item.title}
                body={item.body}
                options={item.options}
                isActive={index === activeSlideIndex}
                onValueChange={setValue}
                value={value}
                errorMessage={errorMessage}
            />
        );
    }

    return (
        <OnboardingSlide
            type={item.type}
            imageUrl={imageUrl}
            title={item.title}
            body={item.body}
            caption={item.caption}
            options={item.options}
            isActive={index === activeSlideIndex}
            onValueChange={setValue}
            value={value}
            errorMessage={errorMessage}
        />
    );
};

const OnboardingFlow = () => {
    const [, setActivePin] = useContext(PinContext);
    const { setLocale, locale } = useContext(LocaleContext);
    const { setAudience } = useContext(AudienceContext);
    const furniture = useContext(FurnitureContext);
    const [{ data: onboarding, isLoading: isOnboardingLoading }] =
        useOnboarding(locale, Onboarding.Default);
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const [isCtaActive, setIsCtaActive] = useState(true);
    const [pin, setPin] = useState("");
    const [studyId, setStudyId] = useState("");
    const [country, setCountry] = useState("");
    const [lang, setLang] = useState("");
    const [path, setPath] = useState(Audience.Default);
    const [errorMessage, setErrorMessage] = useState("");
    const [, setStoredPin] = useStorage(STORAGE_KEY_STORED_PIN);

    const frame = useSafeAreaFrame();
    const insets = useSafeAreaInsets();
    const carousel = useRef<ICarouselInstance>();
    const isLoading = !frame.width || isOnboardingLoading;
    const slides = onboarding?.onboardingSlideIndex;
    const {
        strings: { pinSafeModeError, nextCta, getStartedCta },
    } = furniture;

    const ctaTitle =
        activeSlideIndex === slides?.length - 1 ? getStartedCta : nextCta;

    console.log(onboarding, isLoading);

    const setLangLocale = lang => {
        let locale = getBestLocale(lang);
        setLang(lang);
        setLocale(locale);
    };

    const setTheAudience = path => {
        setPath(path);
        setAudience(path);
    };

    useEffect(() => {
        if (slides) {
            const activeSlide = slides[activeSlideIndex];

            if (activeSlide.type === OnboardingSlideType.Pin) {
                if (pin.length === 4) {
                    if (`${pin}` == "0000") {
                        setErrorMessage(pinSafeModeError);
                        return setPin("");
                    }
                    setIsCtaActive(true);
                } else {
                    pin.length && setErrorMessage("");
                    setIsCtaActive(false);
                }
            } else if (activeSlide.type === OnboardingSlideType.Country) {
                setIsCtaActive(!!country);
            } else if (activeSlide.type === OnboardingSlideType.Language) {
                setIsCtaActive(!!lang);
            } else if (activeSlide.type === OnboardingSlideType.Audience) {
                setIsCtaActive(!!path);
            }
        }
    }, [slides, activeSlideIndex, pin, country, lang, path]);

    const onNextPress = () => {
        if (slides) {
            // slides available to navigate
            if (activeSlideIndex < slides.length - 1) {
                setIsCtaActive(false);
                // debounce(() => setActiveSlideIndex(activeSlideIndex + 1), 100);
                carousel?.current.next();
                // last slide
            } else {
                setStoredPin(pin);
                setActivePin(pin);
                register(country, lang, path);
            }
        }
    };

    if (isLoading) {
        return <Container />;
    }

    return (
        <Container>
            <Carousel
                ref={carousel}
                data={slides}
                onScrollStart={() => {
                    setIsCtaActive(false);
                }}
                onScrollEnd={() => setIsCtaActive(true)}
                renderItem={({ item, index }: CarouselProps) => {
                    let setValue;
                    let value;
                    switch (item.type) {
                        case OnboardingSlideType.Pin:
                            setValue = setPin;
                            value = pin;
                            break;
                        case OnboardingSlideType.StudyId:
                            setValue = setStudyId;
                            value = studyId;
                            break;
                        case OnboardingSlideType.Country:
                            setValue = setCountry;
                            value = country;
                            break;
                        case OnboardingSlideType.Language:
                            setValue = setLangLocale;
                            value = lang;
                            break;
                        case OnboardingSlideType.Audience:
                            setValue = setTheAudience;
                            value = path;
                            break;
                    }

                    return renderItem({
                        item,
                        index,
                        setValue,
                        activeSlideIndex,
                        value,
                        errorMessage,
                    });
                }}
                width={frame.width}
                height={frame.height - insets.bottom - insets.top - CTA_HEIGHT}
                enabled={false}
                onSnapToItem={setActiveSlideIndex}
                snapEnabled={true}
            />
            <PaginationContainer>
                <Pagination
                    dotsLength={slides.length}
                    activeDotIndex={activeSlideIndex}
                    containerStyle={{ paddingVertical: 0 }}
                    dotColor="#58C4C6"
                    dotStyle={{
                        width: 15,
                        height: 15,
                        borderRadius: 8,
                    }}
                    inactiveDotColor="#E8E3DC"
                    inactiveDotOpacity={1}
                    inactiveDotStyle={{
                        borderRadius: 10,
                    }}
                    inactiveDotScale={0.67}
                />
            </PaginationContainer>
            <Cta>
                <Button
                    type={isCtaActive ? "default" : "inactive"}
                    title={ctaTitle}
                    onPress={onNextPress}
                />
            </Cta>
        </Container>
    );
};

export default OnboardingFlow;
