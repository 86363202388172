import React from "react";
import { Platform } from "react-native";
import { Article } from "myplan";
import { OnboardingSlideProps } from "../../index";
import { TextInput, Spacer } from "../../styles";
import { Container, KeyboardAvoidingView } from "./styles";

const OnboardingSlideStudyId = ({
	title,
	body,
	caption,
	value,
	onValueChange,
}: OnboardingSlideProps) => {
	return (
		<Container>
			<Spacer />
			<Article title={title} body={body} caption={caption} />
			<KeyboardAvoidingView
				behavior={Platform.OS == "ios" ? "padding" : "height"}>
				<TextInput
					value={value}
					onChangeText={onValueChange}></TextInput>
			</KeyboardAvoidingView>
			<Spacer />
			<Spacer />
		</Container>
	);
};

export default OnboardingSlideStudyId;
