import { registerRootComponent } from "expo";
import "react-native-get-random-values";
import crypto, { polyfillWebCrypto } from "expo-standard-web-crypto";

if (global.crypto === undefined) {
    // @ts-ignore
    global.crypto = crypto;
}

polyfillWebCrypto();

import App from "screens/App";

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App);
