import styled from "@emotion/native";
import {} from "react-native";
import Text from "myplan/components/Text";

export const InitializingContainer = styled.View(
	{
		padding: 40,
		display: "flex",
		flexDirection: "column",
		alignContent: "center",
		justifyContent: "center",
		width: "100%",
		height: "100%",
	},
	({ theme }: any) => {
		return {
			backgroundColor: theme.color.background,
		};
	}
);

export const InitializingTitle = styled(Text)({
	textAlign: "center",
	paddingVertical: 40,
});
