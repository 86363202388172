import React from "react";
import { Article } from "myplan";
import { OnboardingSlideProps } from "../..";
import { Container, Accessory, Main, Image } from "../../styles";

const OnboardingSlideReverse = ({
	title,
	body,
	caption,
	imageUrl,
}: OnboardingSlideProps) => {
	return (
		<Container>
			<Accessory>
				<Article
					type="reverse"
					title={title}
					body={body}
					caption={caption}
				/>
				<Main isReverse>
					{imageUrl && <Image source={{ uri: imageUrl }} />}
				</Main>
			</Accessory>
		</Container>
	);
};

export default OnboardingSlideReverse;
