import React, { useState, useEffect, useContext } from "react";
import { useNavigationState } from "@react-navigation/native";
import { useNavigation } from "@react-navigation/native";
import { keyBy } from "lodash";
import DrawerComponent from "../Drawer";
import Home from "../Home";
import MyRelationship from "../MyRelationship";
import MyStrategies from "../MyStrategies";
import MyResources from "../MyResources";
import MySettings from "../MySettings";
import Learn from "../Learn";
import About from "../About";
import Bookmarks from "../Bookmarks";
import { SafeModeButton } from "myplan";
import { useExternalState } from "myplan/utils/me";
import { getQuizOrder, getStrategyOrder } from "myplan/utils/contentful";
import { QuizOrderContext } from "myplan/utils/quiz";
import {
    StrategyOrderContext,
    StrategyCategoryOrderContext,
} from "myplan/utils/strategy";
import { FurnitureContext } from "myplan/utils/furniture";
import { createDrawerNavigator, RouterType } from "myplan/utils/routing";
import { NavContext } from "myplan/utils/nav";
import { Container, SafeModeButtonContainer } from "./styles";

const Drawer = createDrawerNavigator(RouterType.Root);

const getLeaf = route => {
    if (!route?.state) {
        return route;
    }

    return getLeaf(route.state.routes[route.state.index]);
};

const getParentState = (navigationState, route) => {
    const activeRoute = navigationState.routes[navigationState.index];
    const isMatch = activeRoute.key === route.key;

    if (isMatch) {
        return navigationState;
    }

    return getParentState(activeRoute.state, route);
};

const isRootRoute = (navigationState, route) => {
    // console.info("isRootRoute: ", navigationState, route);
    const activeRoute = navigationState.routes[navigationState.index];

    if (activeRoute.name !== "Root") {
        return false;
    }

    const result = activeRoute?.state?.routes?.reduce((acc, _route) => {
        if (acc) {
            return acc;
        }

        return _route.name === route.name;
    }, false);

    return !!result;
};

const Root = () => {
    const furniture = useContext(FurnitureContext);
    const navigation = useNavigation();
    const { quizStates, logicStates, contentStates } = useExternalState();
    const [quizOrder, setQuizOrder] = useState(null);
    const [strategyOrder, setStrategyOrder] = useState(null);
    const [strategyCategoryOrder, setStrategyCategoryOrder] = useState(null);
    const navigationState = useNavigationState(state => state);
    const [canGoBack, setCanGoBack] = useState(false);
    const [navMap, setNavMap] = useState<any>({});

    useEffect(() => {
        const activeRoute = navigationState.routes[navigationState.index];
        // console.info("Root activeRoute: ", activeRoute);
        let result = false;

        if (activeRoute.name === "Root") {
            const leaf = getLeaf(navigationState.routes[navigationState.index]);
            const isRoot = isRootRoute(navigationState, leaf);

            if (!isRoot && leaf.name !== "Root") {
                result = true;
            }
        }

        setCanGoBack(result);
    });

    // console.info("Root canGoBack: ", canGoBack);

    const goBack = () => {
        const leaf = getLeaf(navigationState.routes[navigationState.index]);
        const leafKey: string = leaf?.key;
        // console.info("Root goBack(): ", navMap, leaf);

        if (leafKey) {
            const leafNav = navMap[leafKey];

            if (leafNav?.canGoBack()) {
                const { [leafKey]: nav, ..._navMap } = navMap;
                leafNav.goBack();
                setNavMap(_navMap);
            } else {
                // fallback to native back nav if no leaf is found
                navigation.goBack();
            }
        }
    };

    const setLeafNav = navigation => {
        const leaf = getLeaf(navigationState.routes[navigationState.index]);

        if (navigation && leaf) {
            setNavMap({
                ...navMap,
                [leaf.key]: navigation,
            });
        }
    };

    useEffect(() => {
        if (furniture && quizStates && logicStates && contentStates) {
            const _quizOrder = getQuizOrder(
                quizStates,
                logicStates,
                furniture.quizIndex,
            );

            const contentStateMap = keyBy(contentStates, "contentId");

            const strategyIndex = furniture.strategyCategoryIndex.reduce(
                (acc, strategyCategory) =>
                    acc.concat(
                        strategyCategory.strategies.map(strategy => ({
                            ...strategy,
                            _meta: {
                                strategyCategoryId: strategyCategory._id,
                                isNew: !contentStateMap[strategy._id],
                            },
                        })),
                    ),
                [],
            );

            const _strategyOrder = getStrategyOrder(logicStates, strategyIndex);
            const availableStrategyCategories =
                furniture.strategyCategoryIndex.reduce(
                    (acc, strategyCategory) => {
                        const matches = strategyCategory.strategies
                            .filter(strategy =>
                                _strategyOrder.available.find(
                                    _strategy => _strategy._id === strategy._id,
                                ),
                            )
                            .map(strategy => {
                                return {
                                    ...strategy,
                                    _meta: {
                                        strategyCategoryId:
                                            strategyCategory._id,
                                        isNew: !contentStateMap[strategy._id],
                                    },
                                };
                            });

                        if (matches.length > 0) {
                            const hasNew = !!matches.find(m => m?._meta?.isNew);
                            const _strategyCategory = {
                                ...strategyCategory,
                                _meta: {
                                    hasNew,
                                },
                                strategies: matches,
                            };

                            acc.push(_strategyCategory);
                        }

                        return acc;
                    },
                    [],
                );

            const _strategyCategoryOrder = {
                all: furniture.strategyCategoryIndex,
                available: availableStrategyCategories,
            };

            setQuizOrder(_quizOrder);
            setStrategyOrder(_strategyOrder);
            setStrategyCategoryOrder(_strategyCategoryOrder);
        } else {
            setQuizOrder(null);
        }
    }, [furniture, quizStates, logicStates, contentStates]);

    const drawerStyle = {
        width: "75%",
    };

    return (
        <NavContext.Provider value={{ canGoBack, goBack, setLeafNav }}>
            <QuizOrderContext.Provider value={quizOrder}>
                <StrategyOrderContext.Provider value={strategyOrder}>
                    <StrategyCategoryOrderContext.Provider
                        value={strategyCategoryOrder}>
                        <Container>
                            <Drawer.Navigator
                                screenOptions={{
                                    headerShown: false,
                                    animationEnabled: true,
                                }}
                                drawerContent={props => (
                                    <DrawerComponent {...props} />
                                )}
                                drawerStyle={drawerStyle}>
                                <Drawer.Screen name="Home" component={Home} />
                                <Drawer.Screen
                                    name="MyRelationship"
                                    component={MyRelationship}
                                    options={{
                                        lazy: false,
                                    }}
                                />
                                <Drawer.Screen
                                    name="MyStrategies"
                                    component={MyStrategies}
                                />
                                <Drawer.Screen
                                    name="MyResources"
                                    component={MyResources}
                                />
                                <Drawer.Screen
                                    name="MySettings"
                                    component={MySettings}
                                />
                                <Drawer.Screen name="Learn" component={Learn} />
                                <Drawer.Screen name="About" component={About} />
                                <Drawer.Screen
                                    name="Bookmarks"
                                    component={Bookmarks}
                                />
                                {/* <Drawer.Screen name="SafeMode" component={SafeMode} /> */}
                            </Drawer.Navigator>
                            <SafeModeButtonContainer>
                                <SafeModeButton />
                            </SafeModeButtonContainer>
                        </Container>
                    </StrategyCategoryOrderContext.Provider>
                </StrategyOrderContext.Provider>
            </QuizOrderContext.Provider>
        </NavContext.Provider>
    );
};

export default Root;
