import React from "react";
import { ViewStyle } from "react-native";
import { Container, RadioTrigger, Check } from "./styles";

export type RadioButtonProps = {
	title: string;
	onPress: () => void;
	isActive?: boolean;
	checked: boolean;
};

const RadioButton = ({
	title,
	onPress,
	isActive = true,
	checked,
}: RadioButtonProps) => {
	const buttonStyle: ViewStyle = {
		paddingHorizontal: 40,
		width: 300,
		// alignItems: "center",
		// justifyContent: "center",
	};
	return (
		<Container>
			{checked && <Check />}
			<RadioTrigger
				containerStyle={buttonStyle}
				title={title}
				onPress={onPress}
				type={isActive ? "default" : "inactive"}
			/>
		</Container>
	);
};

export default RadioButton;
