import React from "react";
import { Article } from "myplan";
import RadioButton from "components/RadioButton";
import { OnboardingSlideProps } from "../../index";
import { Container, Accessory, Main } from "../../styles";
import { Selections } from "./styles";

const OnboardingSlideSelect = ({
    title,
    body,
    caption,
    options,
    value: currentValue,
    onValueChange,
}: OnboardingSlideProps) => {
    const getHandleOnPressSetOption = option => {
        return () => onValueChange(option);
    };
    return (
        <Container>
            <Accessory>
                <Article
                    type="reverse"
                    title={title}
                    body={body}
                    caption={caption}
                />
                <Main isReverse>
                    <Selections>
                        {options.map(({ key, value }) => (
                            <RadioButton
                                key={key}
                                onPress={getHandleOnPressSetOption(key)}
                                title={value}
                                checked={key === currentValue}
                            />
                        ))}
                    </Selections>
                </Main>
            </Accessory>
        </Container>
    );
};

export default OnboardingSlideSelect;
