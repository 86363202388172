import React, { useRef, useState, useEffect, useContext } from "react";
import { ActivityIndicator, ImageBackground } from "react-native";
import { useTheme } from "@emotion/react";
import { useScrollToTop } from "@react-navigation/native";
import { AppTheme } from "myplan/utils/theme";
import { QuizOrderContext } from "myplan/utils/quiz";
import { FurnitureContext } from "myplan/utils/furniture";
import { createStackNavigator, RouterType } from "myplan/utils/routing";
import { getQuizOrder } from "myplan/utils/contentful";
import { useExternalState } from "myplan/utils/me";
import { DrawerButton, Quiz, QuizQueue, QuizCta } from "myplan";

import {
    Container,
    Scroll,
    Title,
    Intro,
    ReviewPrompt,
    LoadingTitle,
} from "./styles";

const Stack = createStackNavigator(RouterType.Quiz);

const Navigator = () => {
    const theme: any = useTheme();
    const furniture = useContext(FurnitureContext);
    const [quizOrder, setQuizOrder] = useState(null);
    const { quizStates, logicStates } = useExternalState();

    useEffect(() => {
        if (furniture && quizStates && logicStates) {
            const _quizOrder = getQuizOrder(
                quizStates,
                logicStates,
                furniture.learningIndex,
            );
            setQuizOrder(_quizOrder);
        } else {
            setQuizOrder(null);
        }
    }, [furniture, quizStates, logicStates]);

    if (!quizOrder) {
        return (
            <Container>
                <ActivityIndicator size="large" />
                <LoadingTitle type="h3">Loading…</LoadingTitle>
            </Container>
        );
    }

    return (
        <QuizOrderContext.Provider value={quizOrder}>
            {/* <Container> */}
            <Stack.Navigator
                initialRouteName="Learn"
                screenOptions={{
                    headerShown: false,
                    cardStyle: {
                        backgroundColor: theme?.color?.background,
                    },
                }}>
                <Stack.Screen name="Learn" component={Learn} />
                <Stack.Screen
                    name="Quiz"
                    component={Quiz}
                    options={{ gestureEnabled: false }}
                />
            </Stack.Navigator>
            {/* </Container> */}
        </QuizOrderContext.Provider>
    );
};

const Learn = ({ navigation }) => {
    const furniture = useContext(FurnitureContext);
    const quizOrder = useContext(QuizOrderContext);
    const { quizStates, logicStates } = useExternalState();
    const { backgroundPattern } = useTheme() as AppTheme;
    const scrollViewRef = useRef(null);
    useScrollToTop(scrollViewRef);

    if (!quizStates || !logicStates) {
        return (
            <Container>
                <ActivityIndicator size="large" />
                <LoadingTitle type="h3">Loading…</LoadingTitle>
            </Container>
        );
    }

    return (
        <Container>
            <DrawerButton />
            <ImageBackground
                source={backgroundPattern}
                resizeMode="cover"
                style={{ flex: 1 }}>
                <Scroll ref={scrollViewRef} showsVerticalScrollIndicator={true}>
                    <Title type="h3">{furniture.myLearningTitle}</Title>
                    <Intro type="mono2">
                        {furniture.strings.myLearningSubhead}
                    </Intro>
                    {quizOrder.next && (
                        <QuizCta
                            quiz={quizOrder.next}
                            onPress={() =>
                                navigation.navigate("Quiz", {
                                    quizId: quizOrder.next._id,
                                })
                            }
                        />
                    )}
                    {quizOrder.queue?.length > 0 && (
                        <>
                            <QuizQueue
                                queue={quizOrder.queue}
                                isDisabled={false}
                                onPressItem={quizId =>
                                    navigation.navigate("Quiz", {
                                        quizId,
                                    })
                                }
                            />
                        </>
                    )}
                    {quizOrder.completed?.length > 0 && (
                        <>
                            <ReviewPrompt type="h8">
                                Review what you've learned
                            </ReviewPrompt>
                            <QuizQueue
                                queue={quizOrder.completed}
                                quizStates={quizStates}
                                logicStates={logicStates}
                                onPressItem={quizId =>
                                    navigation.navigate("Quiz", {
                                        quizId,
                                        isReview: true,
                                    })
                                }
                                isComplete
                            />
                        </>
                    )}
                </Scroll>
            </ImageBackground>
        </Container>
    );
};

export default Navigator;
