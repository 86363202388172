import { useFirestore, useAuth } from "myplan/utils/firebase";
import { LogicActionType } from "myplan/screens/Quiz";
import { getGlobalLanguageFlag, getBestLocale } from "myplan/utils/locale";
import { Audience } from "myplan/utils/furniture";

/**
 * Create a new user record after logging into Firebase,
 * set preferred country and language
 *
 * @param country
 * @param language
 */
export const register = async (
	country: string,
	language: string,
	audience: Audience = Audience.Default
) => {
	const auth = useAuth();
	const firestore = useFirestore();
	const {
		user: { uid, metadata },
	} = await auth().signInAnonymously();

	// find a matching locale, or fallback to en-US
	let locale = getBestLocale(language);

	const me: any = {
		uid,
		createdAt: new Date(metadata.creationTime),
		language,
		country,
		locale,
		audience,
		theme: "darkTheme",
	};

	await firestore().collection("users").doc(uid).set(me);

	const logicStateDoc = firestore()
		.collection("users")
		.doc(uid)
		.collection("logicStates")
		.doc();

	// simulate a quiz result to create a logic state for content tailoring
	// the "global" content state is rebuilt from the stream, so we must
	// simulate a question answer
	await logicStateDoc.set({
		global: {
			[getGlobalLanguageFlag(language)]: true,
		},
		local: {},
		quizId: null,
		stream: [
			{
				key: getGlobalLanguageFlag(language),
				optionIds: [],
				questionId: null,
				type: LogicActionType.SetGlobalFlag,
				value: true,
			},
		],
	});
};

export const logout = async () => {
	const auth = useAuth();
	await auth().signOut();
};

export default {
	logout,
	register,
};
