import {
    DefaultTheme,
    NavigationContainer,
    useNavigationContainerRef,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import {
    LocaleContext,
    Locales,
    STORAGE_KEY_STORED_LOCALE,
} from "myplan/utils/locale";
import {
    STORAGE_KEY_EXTERNAL_RESOURCES,
    STORAGE_KEY_EXTERNAL_RESOURCES_ZIP,
} from "myplan/utils/resources";
import { useStorage } from "myplan/utils/storage";
import React, { useEffect, useRef, useState } from "react";
import {
    ActivityIndicator,
    AppState,
    useWindowDimensions,
    LogBox,
    Platform,
} from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import Inactive from "../Inactive";
import ModalScreen from "../ModalScreen";
import OnboardingFlow from "../OnboardingFlow";
import PinUnlock from "../PinUnlock";
import Root from "../Root";
import SafeMode from "../SafeMode";

import { ThemeProvider } from "@emotion/react";
import { AppTheme } from "myplan/utils/theme";
import { darkTheme, lightTheme, STORED_THEME, ThemeContext } from "utils/theme";

import {
    Merriweather_400Regular,
    Merriweather_400Regular_Italic,
    Merriweather_700Bold,
    Merriweather_700Bold_Italic,
    useFonts as useMerriwetherFonts,
} from "@expo-google-fonts/merriweather";
import {
    Raleway_400Regular,
    Raleway_400Regular_Italic,
    Raleway_500Medium,
    Raleway_500Medium_Italic,
    Raleway_700Bold,
    Raleway_700Bold_Italic,
    useFonts as useRalewayFonts,
} from "@expo-google-fonts/raleway";
import { useFurniture } from "myplan/utils/contentful";
import { useAuth, useFirestore, useAnalytics } from "myplan/utils/firebase";
import {
    Audience,
    AudienceContext,
    FurnitureContext,
    STORAGE_KEY_STORED_AUDIENCE,
} from "myplan/utils/furniture";
import { Context as MeContext } from "myplan/utils/me";
import {
    Context as PinContext,
    STORAGE_KEY_STORED_PIN,
} from "myplan/utils/pin";
import {
    defaultLogicState,
    LogicState,
    LogicStateContext,
    STORAGE_KEY_LOGIC_STATE,
} from "myplan/utils/quiz";
import { onAfterReload } from "utils/reload";

import { InitializingContainer, InitializingTitle } from "./styles";

// @ts-ignore
declare const global: { HermesInternal: null | {} };

const DEFAULT_SCREEN_HEIGHT = 896;

const Stack = createStackNavigator();

const navTheme = {
    ...DefaultTheme,
    colors: {
        ...DefaultTheme.colors,
        background: "transparent",
    },
};

const renderOnboardingFlow = () => {
    return (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
            <Stack.Screen name="Login" component={OnboardingFlow} />
        </Stack.Navigator>
    );
};

const renderRoot = me => {
    return (
        <MeContext.Provider value={me}>
            <Stack.Navigator
                screenOptions={{
                    headerShown: false,
                    presentation: "modal",
                    animationEnabled: true,
                    cardStyle: {
                        flex: 1,
                    },
                }}
                initialRouteName="Root">
                <Stack.Screen name="Root" component={Root} />
                <Stack.Screen name="ModalScreen" component={ModalScreen} />
                <Stack.Screen
                    name="Safe Mode"
                    component={SafeMode}
                    initialParams={{ returnRoute: "Root" }}
                />
            </Stack.Navigator>
        </MeContext.Provider>
    );
};

const renderUnlock = () => {
    return (
        <Stack.Navigator
            screenOptions={{ headerShown: false, presentation: "modal" }}
            initialRouteName="PinUnlock">
            {/* @ts-ignore */}
            <Stack.Screen name="PinUnlock" component={PinUnlock} />
            <Stack.Screen
                name="Safe Mode"
                component={SafeMode}
                initialParams={{ returnRoute: "PinUnlock" }}
            />
        </Stack.Navigator>
    );
};

const renderInactive = () => <Inactive />;

const App = () => {
    const auth = useAuth();
    const navigationRef = useNavigationContainerRef();
    const routeNameRef = useRef("");
    const [, logScreenView] = useAnalytics();
    const [me, setMe] = useState(null);
    const [_locale, setActiveLocale] = useState(null);
    const [_audience, setActiveAudience] = useState(null);
    const [storedPin, , clearStoredPin] = useStorage(STORAGE_KEY_STORED_PIN);
    const [storedAudience, setStoredAudience, clearStoredAudience] = useStorage(
        STORAGE_KEY_STORED_AUDIENCE,
    );
    const [storedLocale, setStoredLocale, clearStoredLocale] = useStorage(
        STORAGE_KEY_STORED_LOCALE,
    );
    const [, , clearStoredResourceZip] = useStorage(
        STORAGE_KEY_EXTERNAL_RESOURCES_ZIP,
    );
    const [, , clearStoredResourceCategories] = useStorage(
        STORAGE_KEY_EXTERNAL_RESOURCES,
    );
    const [{ data: furniture }] = useFurniture(_locale, _audience);
    const [activePin, setActivePin] = useState("");
    const [isInitializing, setIsInitializing] = useState(true);
    const [theme, setStoredTheme] = useStorage(STORED_THEME);
    const { height } = useWindowDimensions();
    const [currentAppStateActive, setCurrentAppStateActive] = useState(true);
    const [_logicState, setLogicState] = useState<LogicState>(null);
    const [_storedLogicState, setStoredLogicState, clearStoredLogicState] =
        useStorage(STORAGE_KEY_LOGIC_STATE);
    const currentAppState = useRef(AppState.currentState);
    const verticalScale =
        height >= DEFAULT_SCREEN_HEIGHT ? 1 : height / DEFAULT_SCREEN_HEIGHT;
    const [ralewayLoaded] = useRalewayFonts({
        Raleway_400Regular,
        Raleway_400Regular_Italic,
        Raleway_500Medium,
        Raleway_500Medium_Italic,
        Raleway_700Bold,
        Raleway_700Bold_Italic,
    });
    const [merriweatherLoaded] = useMerriwetherFonts({
        Merriweather_400Regular,
        Merriweather_400Regular_Italic,
        Merriweather_700Bold,
        Merriweather_700Bold_Italic,
    });
    let content;

    // bad. boo.
    LogBox.ignoreAllLogs();

    // get currently stored or default theme
    // ensure stored theme is up to date
    // in case the theme "shape" has been updated
    // in a recent deployment
    const storedTheme: AppTheme =
        theme?.name === "light"
            ? { verticalScale, ...lightTheme }
            : { verticalScale, ...darkTheme };

    const updateTheme = newTheme => {
        let theme = { ...newTheme, verticalScale };
        setStoredTheme(theme);
    };

    const setAudience = (audience: Audience) => {
        setActiveAudience(audience);
        setStoredAudience(audience);
    };

    const setLocale = (locale: Locales) => {
        setActiveLocale(locale);
        setStoredLocale(locale);
    };

    const handleAppState = nextAppState => {
        // disable auto-locking in development
        if (__DEV__) return;
        if (nextAppState.match(/inactive|background/)) {
            currentAppState.current = nextAppState;
            setCurrentAppStateActive(!currentAppState.current);
            setActivePin(null);
        }

        if (nextAppState === "active") {
            currentAppState.current = nextAppState;
            setCurrentAppStateActive(!!currentAppState.current);
        }
    };

    const handleBlur = e => {
        setActivePin(null);
    };

    useEffect(() => {
        const firestore = useFirestore();
        const unsubscribeFn = auth().onAuthStateChanged(user => {
            if (isInitializing) {
                setIsInitializing(false);
            }

            // Make it ez to find my records in Cloud Firestore
            console.log("Current user ID", user?.uid);

            if (!user) {
                clearStoredPin();
                clearStoredResourceZip();
                clearStoredResourceCategories();
                setActivePin(null);
                clearStoredAudience();
                clearStoredLocale();
                setActiveAudience(Audience.Default);
                setActiveLocale(Locales.EN_US);
                clearStoredLogicState();
            } else {
                firestore()
                    .collection("users")
                    .doc(user.uid)
                    .get()
                    .then(snap => {
                        const data = snap.data();
                        const { audience, locale } = data;
                        setAudience(audience);
                        setLocale(locale);
                    });
            }

            setMe(user);
        });

        return unsubscribeFn;
    }, []);

    useEffect(() => {
        const appStateChangeListener = AppState.addEventListener(
            "change",
            handleAppState,
        );

        let blurListener;
        if (Platform.OS === "android") {
            blurListener = AppState.addEventListener("blur", handleBlur);
        }

        return () => {
            setActivePin(null);
            appStateChangeListener?.remove();
            blurListener?.remove();
        };
    }, []);

    useEffect(() => {
        onAfterReload(() => {
            setActivePin(null);
        });
    }, []);

    // if a stored audience is set, set it as active
    useEffect(() => {
        if (!_audience) {
            setActiveAudience(storedAudience || Audience.Default);
        }
    }, []);

    // if a stored locale is set, set it as active
    useEffect(() => {
        if (!_locale) {
            setActiveLocale(storedLocale || Locales.EN_US);
        }
    }, []);

    // use default or stored logic state if none has been set by Quiz
    useEffect(() => {
        if (!_logicState) {
            setLogicState(_storedLogicState || defaultLogicState);
        }
    }, []);

    // exit early if fonts have not been loaded.
    const fontsLoaded = merriweatherLoaded && ralewayLoaded;
    if (!fontsLoaded) return null;

    if (isInitializing || !furniture) {
        return (
            <SafeAreaProvider>
                <ThemeProvider theme={storedTheme}>
                    <InitializingContainer>
                        <ActivityIndicator size="large" />
                        <InitializingTitle type="caption1">
                            Initializing...
                        </InitializingTitle>
                    </InitializingContainer>
                </ThemeProvider>
            </SafeAreaProvider>
        );
    }

    if (me && storedPin) {
        if (activePin) {
            content = renderRoot(me);
        } else {
            content = renderUnlock();
        }
    } else {
        // enforce logout on reinstall or restart
        auth().signOut();
        content = renderOnboardingFlow();
    }

    return (
        <SafeAreaProvider>
            <ThemeContext.Provider value={{ theme: storedTheme, updateTheme }}>
                <ThemeProvider theme={storedTheme}>
                    <LogicStateContext.Provider
                        value={{
                            logicState: _logicState,
                            setLogicState: logicState =>
                                setLogicState(logicState),
                            storeLogicState: logicState =>
                                setStoredLogicState(logicState),
                        }}>
                        <LocaleContext.Provider
                            value={{ locale: _locale, setLocale }}>
                            <AudienceContext.Provider
                                value={{
                                    audience: _audience,
                                    setAudience,
                                }}>
                                <FurnitureContext.Provider value={furniture}>
                                    <PinContext.Provider
                                        value={[activePin, setActivePin]}>
                                        <NavigationContainer
                                            onReady={() => {
                                                if (navigationRef.isReady()) {
                                                    routeNameRef.current =
                                                        navigationRef?.getCurrentRoute()
                                                            ?.name ?? "";
                                                }
                                            }}
                                            onStateChange={() => {
                                                if (!navigationRef.isReady())
                                                    return;
                                                const previousRouteName =
                                                    routeNameRef.current;
                                                const currentRouteName =
                                                    navigationRef?.getCurrentRoute()
                                                        ?.name ?? "";

                                                if (
                                                    previousRouteName !==
                                                    currentRouteName
                                                ) {
                                                    routeNameRef.current =
                                                        currentRouteName;
                                                    logScreenView(
                                                        currentRouteName,
                                                    );
                                                }
                                            }}
                                            theme={navTheme}
                                            documentTitle={{
                                                enabled: false,
                                            }}>
                                            {currentAppStateActive && content}
                                            {!currentAppStateActive &&
                                                renderInactive()}
                                        </NavigationContainer>
                                    </PinContext.Provider>
                                </FurnitureContext.Provider>
                            </AudienceContext.Provider>
                        </LocaleContext.Provider>
                    </LogicStateContext.Provider>
                </ThemeProvider>
            </ThemeContext.Provider>
        </SafeAreaProvider>
    );
};

export default App;
