import React from "react";
import { useTheme } from "@emotion/react";
import { AppTheme } from "myplan/utils/theme";
import { Text } from "myplan";
import { Container, Aside, Main } from "./styles";
import { TouchableOpacity } from "react-native";

const FooterItem = ({ Icon, title, caption, onPress }) => {
	const {
		color: { accent },
	}: AppTheme = useTheme() as AppTheme;
	return (
		<TouchableOpacity onPress={onPress}>
			<Container>
				<Aside>
					<Icon color={accent} width="100%" height="30" />
				</Aside>
				<Main>
					<Text type="mono1">{title}</Text>
					<Text type="body2">{caption}</Text>
				</Main>
			</Container>
		</TouchableOpacity>
	);
};

export default FooterItem;
