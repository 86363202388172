import styled from "@emotion/native";
import { Container as Container_ } from "../../styles";

export const Container = styled(Container_)({
	justifyContent: "center",
});

export const KeyboardAvoidingView = styled.KeyboardAvoidingView({
	marginTop: 20,
});
