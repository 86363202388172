import primaryIcon from "../../../assets/1024.png";
import timecheckIcon from "../../../assets/icons/timecheckIcon.png";
import organizationIcon from "../../../assets/icons/organizationIcon.png";

const primary = "primary",
	timecheck = "timecheck",
	organization = "organization";

export const iconNames = { primary, timecheck, organization };
export const iconThumbnails = { primaryIcon, timecheckIcon, organizationIcon };

export const STORED_ICON = "icons/storedIcon";
