import styled from "@emotion/native";
import { ViewStyle } from "react-native";
export const SLIDE_PADDING_HORIZONTAL = 30;
export const CTA_HEIGHT = 50;

export const Container = styled.SafeAreaView({
    flex: 1,
    justifyContent: "flex-start",
    paddingHorizontal: SLIDE_PADDING_HORIZONTAL,
    paddingVertical: 0,
});

export const ErrorMain = styled.View({});

export const Main = styled.View({}, ({ isReverse, theme }: any) => {
    const flex = theme.verticalScale < 1 ? 1.3 : 1.6;
    let style: ViewStyle = {
        flex,
        marginTop: 20,
    };

    if (isReverse) {
        style = {
            ...style,
            marginBottom: 50,
        };
    }

    return style;
});

export const Accessory = styled.View(
    {
        paddingHorizontal: 10,
    },
    ({ isFlexDisabled }: any) => {
        let style: ViewStyle = {
            flex: 1,
        };

        if (isFlexDisabled) {
            style = {};
        }

        return style;
    },
);

export const TextInput = styled.TextInput(
    {
        paddingHorizontal: 20,
        height: 50,
        borderRadius: 5,
        marginTop: 20,
    },
    ({ theme }: any) => {
        return {
            backgroundColor: theme.color.text,
            color: theme.color.inputText,
        };
    },
);

export const Spacer = styled.View({
    flexGrow: 1,
});

export const Image = styled.Image({
    flex: 1,
    width: "100%",
    resizeMode: "contain",
});
