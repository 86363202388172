import React from "react";
import { Article } from "myplan";
import { OnboardingSlideProps } from "../../index";
import { Container, Accessory, Main, Image } from "../../styles";

const OnboardingSlideDefault = ({
    title,
    body,
    caption,
    imageUrl,
}: OnboardingSlideProps) => {
    return (
        <Container>
            <Main>{imageUrl && <Image source={{ uri: imageUrl }} />}</Main>
            <Accessory>
                <Article title={title} body={body} caption={caption} />
            </Accessory>
        </Container>
    );
};

export default OnboardingSlideDefault;
