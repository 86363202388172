import { createContext } from "react";
import { AppTheme } from "myplan/utils/theme";
import { noop } from "lodash";
import { MyPlanLogoDark, MyPlanLogoLight } from "myplan/components/Logo";

import backgroundPattern from "./resources/background.png";
import backgroundParallax from "./resources/parallaxBG.png";

import backgroundPatternLight from "./resources/backgroundLight.png";
import backgroundParallaxLight from "./resources/parallaxBGLight.png";

const headingSemiBold = "Merriweather_700Bold";
const headingExtraBold = "Merriweather_400Regular";
const headingBoldItalic = "Merriweather_400Regular_Italic";

const bodyMedium = "Raleway_500Medium";
const bodyRegular = "Raleway_400Regular";
const bodyBold = "Raleway_700Bold";
const bodyItalic = "Raleway_400Regular_Italic";

const monoRegular = "Raleway_400Regular";
const monoBoldItalic = "Merriweather_700Bold_Italic";

const fonts = {
    headingExtraBold,
    headingSemiBold,
    headingBoldItalic,
    bodyMedium,
    bodyRegular,
    bodyBold,
    bodyItalic,
    monoRegular,
    monoBoldItalic,
};

const textStyles = {
    h1: {
        fontFamily: headingSemiBold,
        fontSize: 28,
        letterSpacing: 1.2,
        lineHeight: 36,
    },
    h2: {
        fontFamily: headingExtraBold,
        fontSize: 18,
        letterSpacing: 1.2,
        lineHeight: 25,
    },
    h3: {
        fontFamily: headingBoldItalic,
        fontSize: 28,
        letterSpacing: 1,
        lineHeight: 30,
    },
    h4: {
        fontFamily: headingSemiBold,
        fontSize: 18,
        lineHeight: 24,
    },
    h5: {
        fontFamily: headingSemiBold,
        fontSize: 30,
        lineHeight: 35,
    },
    h6: {
        fontFamily: headingSemiBold,
        fontSize: 45,
        lineHeight: 45,
        letterSpacing: 22,
    },
    h7: {
        fontFamily: headingBoldItalic,
        fontSize: 18,
        letterSpacing: 22,
    },
    h8: {
        fontFamily: headingSemiBold,
        fontSize: 24,
        lineHeight: 35,
    },
    h9: {
        fontFamily: bodyMedium,
        fontSize: 15,
        lineHeight: 20,
    },
    h10: {
        fontFamily: bodyRegular,
        fontSize: 20,
        lineHeight: 22,
    },
    body1: {
        fontFamily: bodyRegular,
        fontSize: 20,
        lineHeight: 25,
    },
    body2: {
        fontFamily: bodyRegular,
        fontSize: 15,
        lineHeight: 20,
    },
    body3: {
        fontFamily: bodyMedium,
        fontSize: 12,
        lineHeight: 16,
    },
    body4: {
        fontFamily: bodyRegular,
        fontSize: 12,
        lineHeight: 20,
    },
    body5: {
        fontFamily: bodyRegular,
        fontSize: 15,
        lineHeight: 20,
    },
    mono1: {
        fontFamily: monoBoldItalic,
        fontSize: 20,
        lineHeight: 30,
    },
    mono2: {
        fontFamily: monoBoldItalic,
        fontSize: 20,
        lineHeight: 25,
        letterSpacing: -0.8,
    },
    mono3: {
        fontFamily: monoBoldItalic,
        fontSize: 20,
        lineHeight: 25,
        letterSpacing: -0.4,
    },
    mono4: {
        fontFamily: monoRegular,
        fontSize: 20,
        lineHeight: 25,
        letterSpacing: -0.4,
    },
    cta1: {
        fontFamily: bodyMedium,
        fontSize: 25,
        lineHeight: 30,
    },
    cta2: {
        fontFamily: bodyMedium,
        fontSize: 20,
        lineHeight: 25,
    },
    cta3: {
        fontFamily: bodyMedium,
        fontSize: 20,
        lineHeight: 25,
    },
    ctah2: {
        fontFamily: headingExtraBold,
        fontSize: 20,
        letterSpacing: 1.2,
        lineHeight: 25,
    },
    ctah3: {
        fontFamily: monoBoldItalic,
        fontSize: 20,
        lineHeight: 25,
        // letterSpacing: -0.8,
    },
    ctah4: {
        fontFamily: bodyRegular,
        fontSize: 20,
        lineHeight: 22,
    },
    ctah5: {
        fontFamily: headingBoldItalic,
        fontSize: 24,
        letterSpacing: 1.2,
        lineHeight: 32,
    },
    caption1: {
        fontFamily: bodyRegular,
        fontSize: 15,
        lineHeight: 20,
    },
    keypad: {
        fontFamily: bodyMedium,
        fontSize: 30,
        lineHeight: 35,
    },
    alert: {
        fontFamily: bodyMedium,
        fontSize: 15,
        lineHeight: 20,
    },
    default: {
        fontFamily: bodyRegular,
        fontSize: 15,
        lineHeight: 20,
    },
};

export const darkTheme: AppTheme = {
    name: "dark",
    color: {
        accent: "#FE5F55",
        secondaryAccent: "#DAFF7D",
        background: "#1B2A46",
        backgroundAccent: "#171738",
        backgroundAccent2: "rgba(181,181,181,0.25)",
        transparentBackground: "rgba(27, 42, 70, 0.8)",
        text: "#FFFFFF",
        link: "#FFFFFF",
        ctaText: "#FFFFFF",
        ctaText2: "#FFFFFF",
        heading: "#FFFFFF",
        pinText: "#f2f2f2",
        inputText: "#26464E",
        interactive: "#DAFF7D",
        enabled: "#2FB1B1",
        active: "#F69D90",
        selected: "#258993",
        disabled: "#CCC6C5",
        disabledText: "#5B5858",
        inactiveMultipleSelectBackground: "rgba(60, 60, 60, .8)",
        activeMultipleSelectBackground: "rgba(37, 137, 147, .64)",
        interstitialBackground: "rgba(3, 26, 40, 0.6)",
        trackColor: "#171738",
        logoPrimary: "#F7F3ED",
        logoSecondary: "#30B7B6",
    },
    textStyles,
    fonts,
    backgroundImage: { logoDark: MyPlanLogoDark, logo: MyPlanLogoDark },
    backgroundPattern,
    backgroundParallax,
};

// #073350

export const lightTheme: AppTheme = {
    name: "light",
    color: {
        accent: "#FE5F55",
        secondaryAccent: "#F69D90",
        background: "#F6F8FF",
        backgroundAccent: "#F1F4F7",
        backgroundAccent2: "#E2E3E9",
        transparentBackground: "rgba(241, 244, 247, 0.8)",
        text: "#1B2A46",
        link: "#1B2A46",
        ctaText: "#FFFFFF",
        ctaText2: "#1B2A46",
        heading: "#1B2A46",
        pinText: "#f2f2f2",
        inputText: "#f2f2f2",
        interactive: "#593C8F",
        enabled: "#2FB1B1",
        active: "#F69D90",
        selected: "#258993",
        disabled: "#CCC6C5",
        disabledText: "#5B5858",
        inactiveMultipleSelectBackground: "rgba(150, 197, 207, .8)",
        activeMultipleSelectBackground: "rgba(37, 137, 147, .64)",
        interstitialBackground: "#F1F4F7",
        trackColor: "#1B2A46",
        logoPrimary: "#575051",
        logoSecondary: "#30B7B6",
    },
    textStyles,
    fonts,
    backgroundImage: { logoLight: MyPlanLogoLight, logo: MyPlanLogoLight },
    backgroundPattern: backgroundPatternLight,
    backgroundParallax: backgroundParallaxLight,
};

export const ThemeContext = createContext({
    theme: darkTheme,
    updateTheme: noop,
});

export const STORED_THEME = "theme/storedTheme";
