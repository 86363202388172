import styled from "@emotion/native";

export const Container = styled.View({
	flexDirection: "row",
	paddingRight: 40,
	marginBottom: 26,
});

export const Aside = styled.View({
	width: 40,
	paddingTop: 6,
	justifyContent: "flex-start",
});

export const Main = styled.View({
	flex: 1,
	marginLeft: 17,
});
